export default {
  // module
  "module.store": "我的商家",
  "module.cooperation-provider": "供應商",

  // data
  "store.data.name": "我的名稱",
  "store.data.member_phone": "手機號碼",
  "store.data.payment_type": "與供應商的付款方式",
  "store.data.comment": "備註(需求品項, 想詢問的品項...)",
  "store.data.member_name": "真實姓名",
  "store.data.member": "聯絡人",
  // form
  "form_block.store.create": "請建立個人帳號",
  "form_block.store.update": "編輯我的個人帳號",

  // others
  "store.co-maintainer": "管理員",
  "cooperation-provider": "供應商",
  "cooperation-provider.search_keyword": "搜尋輸入供應商名稱、聯絡人、電話",
  "store.order.search_keyword": "輸入訂單編號搜尋",
};
