import Vue from 'vue'
import validator, { ValidatorInterface } from 'kernel/helper/validator'
import account, { AccountInterface } from 'kernel/helper/account'
import dayjs from 'dayjs'
class helper {
  protected vm: Vue | null
  public validator: ValidatorInterface | null
  public account: AccountInterface | null

  constructor() {
    this.vm = null
    this.account = null
    this.validator = null
  }

  setupVueModel(vm: Vue) {
    this.vm = vm
    this.validator = validator(this, this.vm)
    this.account = account(this, this.vm)
  }

  async copyText(text: any, container: any) {
    if (!container) container = document.body
    if (!this.vm) return
    try {
      await this.vm.$copyText(text, container)
    } catch (error) {
      console.error(error)
    }
    this.vm.$snotify.success(text, this.vm.$t('copy.successfully'))
  }

  delay(second = 1): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, second * 1000)
    })
  }

  focus(selector: string, $el?: HTMLElement): void {
    // @ts-ignore
    const $target = !$el ? $(selector) : $($el).find(selector)
    $target.focus()
  }

  now(format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return dayjs().format(format)
  }

  orderTimeFormat(time: string) {
    const formatType = this.isMobile() ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
    return  dayjs(time).format(formatType)
  }

  // 回傳當下時戳(秒)
  currentTimestamp() {
    return Math.floor(new Date().getTime() / 1000)
  }

  getProductMasterPhoto(product: any): AnyObject | null {
    if (!product) return null
    const instances = !Array.isArray(product.instances) ? [] : product.instances
    const instancePhotos = instances
      .map((instance: AnyObject) => instance.photo)
      .filter((photo: any) => !!photo)
    const productPhotos = this.getComputedPhotoList(product.photos) || []
    const photos = productPhotos.concat(instancePhotos)
    return photos[0] || null
  }

  getPhotoListFirstItem(photos: any, suffixList?: string[]): string | null {
    if (!Array.isArray(photos)) return null
    const photoList = photos.filter(photo => !!photo)
    if (photoList.length == 0) return null
    return photoList[0] || null
  }

  createProductInstanceSets(instanceConfig: any, delimiter: string | null): any {
    if (!delimiter) delimiter = '-'
    const baseInstanceData = {
      sku: null,
      price: 0,
      stock: 0,
    }
    if (!Array.isArray(instanceConfig)) {
      return [{
        name: null,
        ...baseInstanceData,
      }]
    }

    if (instanceConfig.length == 0) {
      return [{
        name: null,
        ...baseInstanceData,
      }]
    }

    if (instanceConfig.length == 1) {
      return instanceConfig[0].attributes.map((attr: any) => ({
        name: attr,
        ...baseInstanceData,
      }))
    }

    const attrs1 = instanceConfig[0].attributes
    const attrs2 = instanceConfig[1].attributes
    const result = []
    for (const attr1 of attrs1) {
      for (const attr2 of attrs2) {
        const name = `${attr1}${delimiter}${attr2}`
        result.push({
          name,
          ...baseInstanceData
        })
      }
    }
    return result
  }

  getComputedPhotoList(photos: any): null | AnyObject[] {
    if (!Array.isArray(photos)) return null
    const validPhotos = photos.filter(photo => !!photo)
    if (validPhotos.length == 0) return null
    return validPhotos
  }

  getPhotoUrl(photo: any, suffixList?: string[]): string | null {
    if (!photo) return null
    if (photo.url) return photo.url
    if (!photo.size_list) return null
    if (!suffixList) suffixList = this.getSuffixListByDevice()
    const originUrl = photo.size_list.origin.url
    for (const suffix of suffixList) {
      if (photo.size_list[suffix] && photo.size_list[suffix].url) {
        return photo.size_list[suffix].url
      }
    }
    return originUrl
  }

  getFileUrl(file: any): null | undefined | string {
    if (!file) return null
    if (file.url) return file.url
    if (typeof file.path != 'string') return null
    const fileBaseUrl = this.vm?.$store.getters['base/application'].fileBaseUrl
    return `${fileBaseUrl}${file.path.substring(1)}`
  }

  getSuffixListByDevice(): string[] {
    const device = this.getDevice()
    if (device === 'xs') return ['middle', 'small', 'tiny']
    if (device === 'sm') return ['middle', 'small', 'tiny']
    if (device === 'md') return ['middle', 'small', 'tiny']
    if (device === 'lg') return ['large', 'middle', 'small', 'tiny']
    return ['xlarge', 'large', 'middle', 'small', 'tiny']
  }

  getDevice(): string | null {
    if (!this.vm) return null
    for (const device of ['xl', 'lg', 'md', 'sm', 'xs']) {
      // @ts-ignore
      const result = this.vm.$vuetify.breakpoint[device]
      if (result === true) return device
    }
    return null
  }

  isMobile(): boolean {
    const device = this.getDevice()
    if (!device) return false
    if (device == 'xl') return false
    if (device == 'lg') return false
    return true
  }

  getComputedLinkList(nodes: any[]): any[] {
    if (!Array.isArray(nodes)) {
      return []
    }
    nodes = window.eagleLodash.cloneDeep(nodes)
    const result = []
    for (const node of nodes) {
      result.push(this._setupMenuNode(node))
    }
    return result
  }

  private _setupMenuNode(node: { [key: string]: any }) {
    const computedNode = window.eagleLodash.cloneDeep(node)
    if (typeof computedNode.create == 'function') {
      computedNode.create = computedNode.create({
        // @ts-ignore
        hasRole: (...args) => window.tokenStore.hasRole(...args),
        tokenStore: window.tokenStore,
        application: this.vm?.$store.getters['base/application'],
      })
    }

    else {
      computedNode.create = true
    }

    if (Array.isArray(computedNode.group)) {
      computedNode.group = this.getComputedLinkList(computedNode.group)
    }

    return computedNode
  }

  public getSiteUserName(user: any) {
    if (!user) return null
    const email = user.email
    const name = user.name
    return name || email
  }

  $t(text: any) {
    if (!this.vm) return null
    return this.vm.$t(text)
  }

  public textEmpty(data: string) {
    return window.eagleLodash.isEmpty(window.eagleLodash.trim(data))
  }

  isImage(file: File) {
    if (file instanceof File === false) return false
    if (new RegExp(/application\/octet-stream/).test(file.type)) return true // android
    if (new RegExp(/image\/heif/).test(file.type)) return true
    if (new RegExp(/image\/heic/).test(file.type)) return true
    if (new RegExp(/image\/png/).test(file.type)) return true
    if (new RegExp(/image\/jpg/).test(file.type)) return true
    if (new RegExp(/image\/jpeg/).test(file.type)) return true
    if (new RegExp(/jpeg/).test(file.type)) return true
    if (new RegExp(/image\/gif/).test(file.type)) return true
    return false
  }

  nestedSetNodeNamePrefix(name: any, depth: number, prefix?: string): string {
    if (!prefix) prefix = ' - '
    const prefixString = prefix.repeat(depth - 1)
    return `${prefixString} ${name}`
  }

  getVideoPhoto(video: AnyObject | null, property?: string | string[]): AnyObject | null {
    if (!video) return null
    if (video.photo) return video.photo
    if (!property) property = 'youtube_url'
    const uid = this.getYoutubeUid(window.eagleLodash.get(video, property))
    const youtubeImage = this.getYoutubeImage(uid)
    if (!youtubeImage) return null
    return {
      url: youtubeImage
    }
  }

  getYoutubeImage(uid: any): null | string {
    if (typeof uid != 'string') return null
    return `https://img.youtube.com/vi/${uid}/hqdefault.jpg`
  }

  getYoutubeUid(url: any): null | string {
    if (typeof url != 'string') return null

    // 短網址形式
    const short_pattern = /(https:\/\/youtu\.be\/)(.*)/
    if (url.match(short_pattern)) {
      let uid = url.replace(short_pattern, '$2')
      const hasQuery = new RegExp(/\?/).test(uid)
      if (hasQuery) {
        uid = uid.split('?')[0]
      }
      return uid
    }

    // 正常形式
    const partial = url.split('v=')
    if (typeof partial[1] != 'string') return null
    const uid = partial[1].split('&')[0]
    return uid
  }

  forceToInteger(data: any, defaultValue = 0): null | number {
    const integer = parseInt(`${data}`)
    if (isNaN(integer)) return defaultValue
    return integer
  }

  amount(text: any): string {
    return `NT${text}`
  }

  // 取得付款中繼頁url
  getPayReturnUrl(orderId: string): string {
    const path = this.vm?.$router.resolve({
      name: 'pay-return',
      params: { id: orderId },
    }).href

    const origin = window.location.origin
    return `${origin}${path}`
  }

  getStringBytes(text: any): number {
    if (typeof text != 'string') return 0
    return text.replace(/[^\x00-\xff]/g, "**").length; // eslint-disable-line
  }

  checkPasswordStrengthLevel(password: any): 'strong' | 'medium' | 'weak' | null {
    if (typeof password != 'string') return null
    if (new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/g).test(password) === true) {
      return 'strong'
    }

    if (new RegExp(/(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/g).test(password) === true) {
      return 'medium'
    }
    return 'weak'
  }

  isInLiffClient() {
    // @ts-ignore
    return window.liff.isInClient();
  }

  generateURL(host: string, path: string, queryParams: { [key: string]: string | string[] }) {
    // Construct the base URL
    let url = `${host}${path}`;

    // Check if there are query parameters
    if (queryParams && Object.keys(queryParams).length > 0) {
      const queryParts: string[] = [];
      for (const key in queryParams) {
        // Use Object.prototype.hasOwnProperty.call for a safer check
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          const value = queryParams[key];
          // Check if the parameter value is an array
          if (Array.isArray(value)) {
            // Handle array values
            value.forEach(val => {
              queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            });
          } else {
              // Handle single values
            queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        }
      }
      // Construct the query string
      if (queryParts.length) {
        url += '?' + queryParts.join('&');
      }
    }

    return url;
  }


  openExternalBrowser(host: string, path: string, queryParams: { [key: string]: string | string[] } = {}) {
    queryParams.openExternalBrowser = "1";
    window.open(this.generateURL(host, path, queryParams));
  }

  closeLiff(second = 0): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        // @ts-ignore
        this.vm.$tokenStore.clean()
        // @ts-ignore
        window.liff.closeWindow();
        resolve()
      }, second * 1000)
    })
  }
  transformDate(date: number): string {
    return ["一", "二", "三", "四", "五", "六", "日"][date - 1];
  }

  transformKey(data: any, config: any) {
    return Object.keys(config)
      .reduce((acc: any, cur: any) => {
        acc[config[cur]] = data[cur]
        return acc
      }, {})
  }

  whereLayout() {
    if(this.vm?.$route.path.match(/provider-store/)) {
      return 'provider-store'
    }
    if(this.vm?.$route.path.match(/provider\//) || this.vm?.$route.path.match(/linerp/)) {
      return 'provider'
    }
    return ''
  }

  vueRoute() {
    return this.vm?.$route
  }

  vueRouter() {
    return this.vm?.$router
  }

  /**
   * ex: 斤從 1-08 -> 1.5
   */
  transformShippingCount({count, unit, force=false}: any) {
    if(force || unit == '斤') {
      const [first, second] = `${count}`.split('-')
      if(!second) return +first
      // @ts-ignore
      const res =  +first + (+second/16)
      return +res
    }

    return count
  }

  /**
   *  ex: 斤從 1.5 -> 1-08
   */
  reverseShippingCount(count: any, unit: any, fixed: number) {
    if(unit != '斤') {
      if(fixed == undefined) return count
      return Number(count).toFixed(fixed)
    }

    const splitResult = `${count}`.split('.')
    const first = splitResult[0]
    let second = splitResult[1]
    if(!second) return +first
    second = ((+count - (+first)) * 16).toFixed(0)
    if(second.length < 2) {
      second = `0${second}`
    }
    return `${first}-${second}`
  }
}

export default new helper()

export interface HelperInterface {
  transformDate: () => (date: number) => string,
  setupVueModel: (vm: Vue) => void,
  validator: ValidatorInterface,
  account: AccountInterface,
  delay: (second: number) => Promise<void>
  focus: (selector: string, $el?: HTMLElement) => void
  now: (format?: string) => string
  orderTimeFormat: (time: string) => string
  currentTimestamp: () => number
  getDevice: () => string | null
  isMobile: () => boolean
  getProductMasterPhoto(product: any): AnyObject | null,
  getPhotoListFirstItem: (photos: any, suffixList?: string[]) => string | null
  createProductInstanceSets: (instanceConfig: any, delimiter: string | null) => any[]
  getPhotoUrl: (photo: any, suffixList?: string[]) => string | null
  getComputedPhotoList(photos: any): null | AnyObject[],
  getComputedLinkList: (nodes: any[]) => any[]
  getSiteUserName: (user: any) => any
  textEmpty: (data: any) => boolean
  isImage: (file: File) => boolean
  nestedSetNodeNamePrefix: (name: any, depth: number, prefix?: '-') => string
  getYoutubeUid: (url: any) => null | string
  getYoutubeImage: (uid: any) => null | string
  getVideoPhoto: (video: AnyObject | null, property?: string | string[]) => AnyObject | null
  forceToInteger: (data: any, defaultValue: number) => null | number,
  amount: (text: any) => string,
  getPayReturnUrl: (orderId: string) => string,
  getStringBytes(text: any): number,
  getFileUrl(file: any): null | undefined | string,
  checkPasswordStrengthLevel(password: any): 'strong' | 'medium' | 'weak',
  transformKey: (data: any, config: any) => any,
  whereLayout:() => string,
  transformShippingCount: ({count, unit, force}: any) => number,
  reverseShippingCount: (count: any, unit: any, fixed: number) => number,
}
